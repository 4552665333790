import React from "react"

// Import Common Components
import { StandardContainer, BodyCopy } from "../../common"

export const CenteredTextBlock = ({ text, id }) => {
  return (
    <StandardContainer padVert id={id}>
      <BodyCopy
        dangerouslySetInnerHTML={{ __html: text.html }}
        textCenter
        wrappedStandard
      />
    </StandardContainer>
  )
}
