import React from "react"
import styled from "styled-components"

import useFormValidation from "./useFormValidation"

// Common Components
import { StyledInput, BodyCopy, ExternalTextLink } from "../common"

// Styles
const StyledContactForm = styled.form`
  ${StyledInput} {
    margin-bottom: 1rem;
  }
  ${ExternalTextLink} {
    float: right;
  }
`

const INITIAL_STATE = {}

export const ContactForm = () => {
  const { handleSubmit, handleChange, success } = useFormValidation(
    INITIAL_STATE
  )
  return (
    <StyledContactForm
      name="contact-form"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      subject="Catering Studio Enquiry"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact-form" />
      <p hidden>
        <label>
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      {success ? (
        <BodyCopy>
          <h2>Thank You</h2>
          <p>One of our team members will contact you shortly.</p>
        </BodyCopy>
      ) : (
        <>
          <StyledInput
            type="text"
            placeholder="Full Name*"
            name="name"
            required="true"
            onChange={handleChange}
          />
          <StyledInput
            type="email"
            placeholder="Email Address*"
            name="Email"
            required="true"
            onChange={handleChange}
          />
          <StyledInput
            type="text"
            placeholder="Date of event"
            name="Event Date"
            onChange={handleChange}
          />
          <StyledInput
            type="text"
            placeholder="Phone"
            name="phone"
            onChange={handleChange}
          />
          <StyledInput
            as="textarea"
            placeholder="Message"
            name="message"
            onChange={handleChange}
          />
          <ExternalTextLink as="button" type="submit">
            Submit
          </ExternalTextLink>
        </>
      )}
    </StyledContactForm>
  )
}
