import React from "react"
import PropTypes from "prop-types"

// Import Slice Components
import {
  PageBanner,
  CenteredTextBlock,
  ImageText,
  FeatureBlock,
  ContactBlock,
} from "./"

export const SliceZone = ({ slices }) => {
  const slice = slices.map(s => {
    switch (s.slice_type) {
      case "banner":
        return <PageBanner {...s} key={s.id} />
      case "centered_text_block":
        return <CenteredTextBlock {...s.primary} key={s.id} id={s.id} />
      case "contact_block":
        return <ContactBlock {...s.primary} key={s.id} id={s.id} />
      case "feature_block":
        return (
          <FeatureBlock {...s.primary} items={s.items} key={s.id} id={s.id} />
        )
      case "image___text":
        return (
          <ImageText
            {...s.primary}
            key={s.id}
            id={s.id}
            layout={s.slice_label}
          />
        )
      default:
        return null
    }
  })
  return <>{slice}</>
}

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}
