import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

// Import common components
import {
  PrismicImage,
  BodyCopy,
  StandardContainer,
  ExternalTextLink,
} from "../../common"

// Styles
const StyledFeatureBlock = styled.div`
  background: ${props => (props.background ? props.background : "#000")};
  color: ${props => (props.textColor ? props.textColor : "#fff")};
  padding: ${props => props.theme.padding.vMobile} 0;
  margin-bottom: ${props => props.theme.padding.vMobile};
  section {
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 1rem;
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
    margin-top: 1rem;
    li {
      margin: 1rem 0;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    section {
      grid-template-columns: 1fr 4rem 1fr;
      > ${BodyCopy} {
        grid-column: 1 / span 2;
        grid-row: 1;
        z-index: +1;
        align-self: center;
      }
      .img-wrapper {
        grid-column: 2 / span 2;
        grid-row: 1;
        opacity: 0.7;
      }
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    padding: ${props => props.theme.padding.vDesk} 0;
    margin-bottom: calc(
      ${props => props.theme.padding.vDesk} + 160 * (100vw - 320px) / 2180
    );
    section {
      grid-template-columns: 1fr 20rem 1fr;
      > ${BodyCopy} {
        grid-column: 1 / span 2;
      }
      .img-wrapper {
        grid-column: 2 / span 2;
      }
    }
    article {
      margin-top: 4rem;
      ${BodyCopy} {
        columns: 2;
        column-gap: 3rem;
        * {
          margin-top: 0;
        }
      }
      ul {
        display: flex;
        li {
          margin-right: 2rem;
        }
      }
    }
  }
`

const variants = {
  visible: { opacity: 1, y: 0 },
  initial: { opacity: 0, y: 20 },
}

export const FeatureBlock = ({
  image,
  subheading,
  heading,
  text,
  background_color,
  text_color,
  items,
  id,
}) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: false,
  })
  return (
    <StyledFeatureBlock
      background={background_color}
      textColor={text_color}
      id={id}
      ref={ref}
    >
      <StandardContainer wrappedStandard>
        <section>
          <div className="img-wrapper">
            <PrismicImage {...image} />
          </div>
          <BodyCopy>
            <motion.h4
              animate={inView ? "visible" : "initial"}
              variants={variants}
              transition={{
                delay: 0.8,
                type: "spring",
                damping: 80,
              }}
            >
              {subheading.text}
            </motion.h4>
            <motion.h1
              animate={inView ? "visible" : "initial"}
              variants={variants}
              transition={{
                delay: 1,
                type: "spring",
                damping: 80,
              }}
            >
              {heading.text}
            </motion.h1>
          </BodyCopy>
        </section>
        <article>
          <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} />
          {items && (
            <ul>
              {items.map(({ link_text, link }) => (
                <li>
                  <ExternalTextLink
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link_text}
                  </ExternalTextLink>
                </li>
              ))}
            </ul>
          )}
        </article>
      </StandardContainer>
    </StyledFeatureBlock>
  )
}
