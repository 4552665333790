import React from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import Scroll from "react-scroll"
import useMeasure from "react-use-measure"
import { useState, useEffect } from "react"
import { ResizeObserver } from "@juggle/resize-observer"

// Import common components
import {
  StandardContainer,
  BodyCopy,
  PrismicBackgroundImage,
} from "../../common"
import DownArrow from "../../../svg/down.svg"

// Styles
const StyledPageBanner = styled(StandardContainer)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #000;
  > ${BodyCopy} {
    z-index: 10;
    color: ${props => props.theme.colors.text.reversed};
  }
  > .image-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    > .dark-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      background: black;
      z-index: 1;
    }
  }
  > button {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4rem;
    z-index: 10;
  }
`

export const PageBanner = ({ primary, items }) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const { heading } = primary

  const scroll = Scroll.animateScroll
  const [ref, measure] = useMeasure({ polyfill: ResizeObserver })

  useEffect(() => {
    if (items?.length > 1) {
      const imageInterval = setInterval(() => {
        setActiveImageIndex(prevIndex => {
          const maxIndex = items.length - 1
          return prevIndex >= maxIndex ? 0 : prevIndex + 1
        })
      }, 6000)

      return () => {
        clearInterval(imageInterval)
      }
    } else {
      setActiveImageIndex(0)
    }
  }, [items?.length])

  return (
    <StyledPageBanner ref={ref}>
      <BodyCopy textCenter>
        <motion.h1
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.8,
            type: "spring",
            damping: 80,
          }}
        >
          {heading.text}
        </motion.h1>
      </BodyCopy>
      <AnimatePresence>
        <motion.div
          key={`HomeBannerImage-${activeImageIndex}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: items?.length > 1 ? 0 : 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="image-container"
        >
          <div className="dark-overlay" />
          <PrismicBackgroundImage
            {...items[activeImageIndex].banner_image_item}
          />
        </motion.div>
      </AnimatePresence>
      <button onClick={() => scroll.scrollTo(measure.height)}>
        <DownArrow />
      </button>
    </StyledPageBanner>
  )
}
