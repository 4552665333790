import React from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

// Components
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import { SliceZone } from "../components/slices"

// Check if preview set
const IS_BROWSER = typeof window !== "undefined"

const IndexPage = ({ data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData })
  const {
    page_title,
    meta_description,
    keywords,
    body,
  } = data.prismicStandardPage.data
  const keywordArray = keywords ? keywords.split(",") : []

  const PageNavItems = body.filter(function(item) {
    if (item.primary) {
      return (
        item.primary.include_in_page_nav === "True" && item.primary.nav_title
      )
    }
    return null
  })
  return (
    <Layout pageNavItems={PageNavItems}>
      <SEO
        title={page_title}
        description={meta_description}
        keywords={keywordArray}
      />
      {body && <SliceZone slices={body} />}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prismicStandardPage(tags: { eq: "Homepage" }) {
      data {
        page_title
        meta_description
        keywords
        body {
          ... on PrismicStandardPageBodyBanner {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              banner_image_item {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicStandardPageBodyCenteredTextBlock {
            id
            slice_type
            primary {
              include_in_page_nav
              nav_title
              text {
                html
              }
            }
          }
          ... on PrismicStandardPageBodyImageText {
            id
            slice_type
            slice_label
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              text {
                html
              }
              include_in_page_nav
              nav_title
              wrapped
            }
          }
          ... on PrismicStandardPageBodyFeatureBlock {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              heading {
                text
              }
              subheading {
                text
              }
              text {
                html
              }
              text_color
              background_color
              include_in_page_nav
              nav_title
            }
            items {
              link {
                url
              }
              link_text
            }
          }
          ... on PrismicStandardPageBodyContactBlock {
            id
            slice_type
            primary {
              text {
                html
              }
              nav_title
              include_in_page_nav
            }
          }
        }
      }
    }
  }
`

export default IndexPage
