import React from "react"
import styled from "styled-components"
import is from "styled-is"

// Import Common Components
import { BodyCopy, PrismicImage } from "../../common"

const StyledImageText = styled.div`
  margin-bottom: ${props => props.theme.padding.vMobile};
  > ${BodyCopy} {
    padding: 2rem ${props => props.theme.padding.hMobile};
  }
  @media ${props => props.theme.breakpoints.medium} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    > ${BodyCopy}, aside {
      align-self: center;
      grid-row: 1;
    }
    ${is("reversed")`
      > ${BodyCopy} {
        grid-column: 1;
      }
      >aside{
        grid-column: 2;
      }
    `}
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column-gap: 4rem;
    padding-right: ${props => props.theme.padding.hDesk};
    margin-bottom: calc(
      ${props => props.theme.padding.vDesk} + 160 * (100vw - 320px) / 2180
    );
    > ${BodyCopy} {
      max-width: 35em;
      margin-left: auto;
      margin-right: auto;
    }
    ${is("reversed")`
      padding-right: 0;
      padding-left: ${props => props.theme.padding.hDesk};
    `}
    ${is("wrapped")`
      max-width: ${props => props.theme.containerWidths.standard};
      margin-left: auto;
      margin-right: auto;
      padding-left: ${props => props.theme.padding.hDesk};
      padding-right: ${props => props.theme.padding.hDesk};
    `}
  }
`

export const ImageText = ({ image, text, wrapped, layout, id }) => {
  return (
    <StyledImageText
      wrapped={wrapped === "True"}
      reversed={layout === "reverse_layout"}
      id={id}
    >
      <aside>
        <PrismicImage {...image} />
      </aside>
      <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} />
    </StyledImageText>
  )
}
