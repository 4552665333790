import React from "react"
import styled from "styled-components"

// Import Common Components
import { BodyCopy, StandardContainer } from "../../common"
import { ContactForm } from "../../forms"

// Styles
const StyledContactBlock = styled(StandardContainer)`
  margin-bottom: ${props => props.theme.padding.vMobile};
  > form {
    margin-top: 2rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    > form,
    ${BodyCopy} {
      align-self: center;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    margin-bottom: calc(
      ${props => props.theme.padding.vDesk} + 160 * (100vw - 320px) / 2180
    );
  }
`

export const ContactBlock = ({ text, id }) => {
  return (
    <StyledContactBlock wrappedStandard>
      <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} id={id} />
      <ContactForm />
    </StyledContactBlock>
  )
}
